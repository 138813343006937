import { setUser } from "../actions/action-creators";
import CampaignFilter from "../components/views/campaign_filter";
import store from "../store";
var axios = require("axios");
let Axios = axios.create();


Axios.interceptors.response.use(response => response, error => {
    if (error.response) {
        let { status } = error.response
        if (status === 401)
            store.dispatch(setUser(null))
    }
    return Promise.reject(error)
})

const config = {
    withCredentials: true
}
var BASE_URL = process.env.REACT_APP_BASE_URL;
var BASE_PATH = BASE_URL + "api/v2/";
const Endpoints = {
    Auth: {
        getUser: () => Axios.get(BASE_PATH + "user/get", config),
        sendOtp: (body) => Axios.post(BASE_PATH + "otp/send", body, config),
        loginOtp: (body) => Axios.post(BASE_PATH + "user/login/otp", body, config),
        logout: () => Axios.get(BASE_PATH + "user/logout", config),
        fetchPermission: (params) => Axios.get(BASE_PATH + "user/permission/mapping/list", { params, ...config }),
    },
    Static: {
        getFunctions: () => Axios.get(BASE_PATH + "static/app/functions", config),
    },
    User: {
        fetch: (params) => Axios.get(BASE_PATH + "user/fetch", { params, ...config }),
        create: (body) => Axios.post(BASE_PATH + "user/add", body, config),
        mapRole: (body) => Axios.post(BASE_PATH + "user/role/mapping/create", body, config),
        deleteRole: (body) => Axios.post(BASE_PATH + "user/role/mapping/active/inactive", body, config),
        fetchManagers: (params) => Axios.get(BASE_PATH + "common/manager/filter", { params, ...config }),
        fetchMappedRole: (params) => Axios.get(BASE_PATH + "user/role/mapping/list", { params, ...config }),
        editMappedRole: (params) => Axios.post(BASE_PATH + "user/role/mapping/edit", params, config ),
        fetchRoleMappedUser: (params) => Axios.get(BASE_PATH + "user/role/user/mapped", { params, ...config }),
        fetchMappedAccess: (params) => Axios.get(BASE_PATH + "user/access/mapping/list", { params, ...config }),
        fetchMappedAccessDetails: (params) => Axios.get(BASE_PATH + "user/access/mapping/detail", { params, ...config }),
        mapAccess: (body) => Axios.post(BASE_PATH + "user/access/mapping/create", body, config),
        inactiveAccess: (body) => Axios.post(BASE_PATH + "user/access/mapping/inactive", body, config),
        sendNotification: (body) => Axios.post(BASE_PATH + "notification/user/send", body, config),
        sendNotificationByFilter: (body) => Axios.post(BASE_PATH + "notification/user/filter/send", body, config),
        fetchUsersByFilter: (params) => Axios.get(BASE_PATH + "common/user/filter", { params, ...config }),
        fetchNewUsers: (params) => Axios.get(BASE_PATH + "common/user/new", { params, ...config }),
        downloadNewUsers: (params) => Axios.get(BASE_PATH + "common/user/new/download", { params, ...config }),
        assignUsers: (body) => Axios.post(BASE_PATH + "user/access/user/map", body, config),
        inactiveAssignedUser: (body) => Axios.post(BASE_PATH + "user/access/edit/mapped/user", body, config),
        getAssignedUser: (params) => Axios.get(BASE_PATH + "user/access/mapped/user", { params, ...config }),
        fetchNotification: (params) => Axios.get(BASE_PATH + "manager/dashboard/fetch/all/notifications", { params, ...config }),
        inactiveNotification: (body) => Axios.post(BASE_PATH + "notification/delete", body, config),
        fetchStories: (params) => Axios.get(BASE_PATH + "manager/dashboard/fetch/all/stories", { params, ...config }),
        inactiveStories: (body) => Axios.post(BASE_PATH + "story/delete", body, config),
        addComment: (body) => Axios.post(BASE_PATH + "user/comment/add", body, config),
        fetchAllRoleAccess: (params) => Axios.get(BASE_PATH + "user/role/fetch/all", { params, ...config }),
        UpdateUserAvailability:(body) => Axios.post(BASE_PATH + "user/available/update", body, config),
    },
    Applicants: {
        fetch: (params) => Axios.get(BASE_PATH + "application/fetch/job", { params, ...config }),
        fetchApplicationTransitions: (params) => Axios.get(BASE_PATH + "application/transition/fetch", { params, ...config }),
        fetchApplicationAccess: (params) => Axios.get(BASE_PATH + "application/access/fetch", { params, ...config }),
        addApplicationAccess: (body) => Axios.post(BASE_PATH + "application/access/add", body, config),
        inactiveApplicationAccess: (body) => Axios.post(BASE_PATH + "application/access/inactive", body, config),
        count: (params) => Axios.get(BASE_PATH + "application/count", { params, ...config }),
        insertApplication: (body) => Axios.post(BASE_PATH + "application/insert/update", body, config),
        shortListed: (body) => Axios.post(BASE_PATH + "application/shortlist", body, config),
        interviews: (body) => Axios.post(BASE_PATH + "application/interviewed", body, config),
        rejected: (body) => Axios.post(BASE_PATH + "application/reject", body, config),
        terminate: (body) => Axios.post(BASE_PATH + "application/terminate", body, config),
        update: (body) => Axios.post(BASE_PATH + "application/update", body, config),
        updateState: (body) => Axios.post(BASE_PATH + "application/status/update/flow", body, config),
        resetInterviewSlot: (body) => Axios.post(BASE_PATH + "application/interview/reset", body, config),
        addApplicantsByExcel: (body) => Axios.post(BASE_PATH + "application/upload", body, config),
        answerAdd: (body) => Axios.post(BASE_PATH + "application/answer/add", body, config),
        changeLocation: (body) => Axios.post(BASE_PATH + "application/location/update", body, config),
        addLead: (body) => Axios.post(BASE_PATH + "application/insert", body, config),
        addComment: (body) => Axios.post(BASE_PATH + "application/comment/add", body, config),
        fetchComment: (params) => Axios.get(BASE_PATH + "application/comment/fetch", { params, ...config }),
        anotherProjectsPartnerLeads: (params) => Axios.get(BASE_PATH + "application/located/users", { params, ...config }),
        sendWarningLetter:(body) => Axios.post(BASE_PATH + "application/send/warning", body, config),
    },
    Employer: {
        fetchEmployer: (params) => Axios.get(BASE_PATH + "employer/fetch", { params, ...config }),
        addEmployer: (body) => Axios.post(BASE_PATH + "employer/add", body, config),
        editEmployer: (body) => Axios.post(BASE_PATH + "employer/edit", body, config),
        inactiveEmployer: (body) => Axios.post(BASE_PATH + "employer/inactive", body, config),
    },
    Files: {
        fileUrl: (body) => Axios.post(BASE_PATH + "file/s3/url/signed", body, config),
        filePutUrl: (body) => Axios.put(BASE_PATH + "file/s3/url/signed", body, config),
        filePublicPutUrl: (body) => Axios.put(BASE_PATH + "file/s3_public/url/signed", body, config),
        uploadFileToS3: (signed_url, file, config = {}) => Axios.put(signed_url, file, config),
        generateFileID: params => Axios.post(BASE_PATH + "file/add", params, config),
        getFileID: params => Axios.get(BASE_PATH + "file/fetch/with/url", { params, ...config }),
    },
    Jobs: {
        select: (params) => Axios.get(BASE_PATH + "job/fetch", { params, ...config }),
        fetch: (params) => Axios.get(BASE_PATH + "job/fetch", { params, ...config }),
        fetchAll: (params) => Axios.get(BASE_PATH + "job/get/all", { params, ...config }),
        fetchJobMetadata: (params) => Axios.get(BASE_PATH + "job/fetch/metadata", { params, ...config }),
        checkJobMandatorySteps: (params) => Axios.get(BASE_PATH + "job/details/check", { params, ...config }),
        fetchJobLocation: (params) => Axios.get(BASE_PATH + "job/location/fetch", { params, ...config }),
        addJob: (body) => Axios.post(BASE_PATH + "job/add", body, config),
        fetchRequirement: (params) => Axios.get(BASE_PATH + "job/eligibility/fetch", {params, ...config}),
        addRequirement: (body) => Axios.post(BASE_PATH + "job/eligibility/add", body, config),
        editRequirement: (body) => Axios.post(BASE_PATH + "job/eligibility/edit", body, config),
        deleteRequirement: (body) => Axios.post(BASE_PATH + "job/eligibility/inactive", body, config),
        addLocation: (body) => Axios.post(BASE_PATH + "job/location/add", body, config),
        jobEdit: (body) => Axios.post(BASE_PATH + "job/edit", body, config),
        jobArchive: (body) => Axios.post(BASE_PATH + "job/archive/update", body, config),
        deleteJob: (body) => Axios.post(BASE_PATH + "job/inactive", body, config),
        jobLocationEdit: (body) => Axios.post(BASE_PATH + "job/location/edit", body, config),
        jobLocationDelete: (body) => Axios.post(BASE_PATH + "job/location/inactive", body, config),
        getFormCount: params => Axios.get(BASE_PATH + `form/status/count`, { params, ...config }),
        getGroupFormResponse: (params) => Axios.get(BASE_PATH + `form/response/group/fetch`, { params, ...config }),
        downloadGiggerReport: (params) => Axios.get(BASE_PATH + "application/report/download", { params, ...config }),
        downloadApplicants: (params) => Axios.get(BASE_PATH + "application/download", { params, ...config }),
        downloadTermination: (params) => Axios.get(BASE_PATH + "application/termination/download", { params, ...config }),
        addJobMedia: (body) => Axios.post(BASE_PATH + "job/media/add", body, config),
        addJobMediaContent: (body) => Axios.post(BASE_PATH + "job/media/content/add", body, config),
        fetchJobMedia: (params) => Axios.get(BASE_PATH + "job/media/fetch", { params, ...config }),
        editJobMedia: (body) => Axios.post(BASE_PATH + "job/media/edit", body, config),
        editJobMediaContent: (body) => Axios.post(BASE_PATH + "job/media/content/edit", body, config),
        inactiveJobMedia: (body) => Axios.post(BASE_PATH + "job/media/inactive", body, config),
        inactiveJobMediaContent: (body) => Axios.post(BASE_PATH + "job/media/content/inactive", body, config),
        fetchJobQues: (params) => Axios.get(BASE_PATH + "job/question/fetch", { params, ...config }),
        addJobQues: (body) => Axios.post(BASE_PATH + "job/question/add", body, config),
        editJobQues: (body) => Axios.post(BASE_PATH + "job/question/edit", body, config),
        deleteJobQues: (body) => Axios.post(BASE_PATH + "job/question/inactive", body, config),
        addWorkOrder: (body) => Axios.post(BASE_PATH + "job/work/order/add", body, config),
        editWorkOrder: (body) => Axios.post(BASE_PATH + "job/work/order/edit", body, config),
        getWorkOrder: (params) => Axios.get(BASE_PATH + "job/work/order/fetch", {...config, params }),
        regenerateWorkOrder: (body) => Axios.post(BASE_PATH + "application/work_order/regenerate", body, config),
        fetchJobInfo: (params) => Axios.get(BASE_PATH + "job/info/fetch/all", {...config, params }),
        addJobInfo: (body) => Axios.post(BASE_PATH + "job/info/add", body, config),
        fetchJobMaterial: (params) => Axios.get(BASE_PATH + "job/info/material/fetch/all", {...config, params }),
        addJobMaterial: (body) => Axios.post(BASE_PATH + "job/info/material/add", body, config),
        inactiveJobMaterial: (body) => Axios.post(BASE_PATH + "job/info/material/inactive", body, config),
        fetchCategory: (params) => Axios.get(BASE_PATH + "job/category/fetch", {...config, params }),
        addCategory: (body) => Axios.post(BASE_PATH + "job/category/add", body, config),
        editCategory: (body) => Axios.post(BASE_PATH + "job/category/edit", body, config),
        getJobsRatingData: (params) => Axios.get(BASE_PATH + "job/ratings/fetch", {...config, params }),
        uploadLead: (body) => Axios.post(BASE_PATH + "application/insert/excel", body, config),

    },
    JobExtraDetails: {
        fetch: (params) => Axios.get(BASE_PATH + "job/extra/details/fetch", {...config, params }),
        fetchCounts: (params) => Axios.get(BASE_PATH + "job/extra/details/counts", {...config, params }),
        add: (body) => Axios.post(BASE_PATH + "job/extra/details/add", body, config),
        delete: (body) => Axios.post(BASE_PATH + "job/extra/details/inactive", body, config),
        updateStatus: (body) => Axios.post(BASE_PATH + "application/extra/details/status/update", body, config),
        downloadData: (params) => Axios.get(BASE_PATH + "job/extra/details/download/excel", {...config, params }),
        uploadData: (body) => Axios.post(BASE_PATH + "job/extra/details/data/upload/excel", body, config),
        uploadSubmittedData: (body) => Axios.post(BASE_PATH + "application/extra/details/submitted/excel", body, config),
        fetchUserData: (params) => Axios.get(BASE_PATH + "job/extra/details/data/fetch", {...config, params }),
    },
    InterviewSlot: {
        addSlot: (body) => Axios.post(BASE_PATH + "slot/interview/add", body, config),
        fetchSlot: (params) => Axios.get(BASE_PATH + "slot/interview/fetch", { params, ...config }),
        inactiveSlot: (params) => Axios.post(BASE_PATH + "slot/interview/inactive", params, config),
        resetSlot: (params) => Axios.post(BASE_PATH + "slot/interview/booking/reset", params, config),
    },
    UserProfile: {
        fetchDocument: (params) => Axios.get(BASE_PATH + "profile/document/fetch", { params, ...config }),
        fetchDeviceInfo: (params) => Axios.get(BASE_PATH + "profile/device/fetch", { params, ...config }),
        fetchUserProjects: (params) => Axios.get(BASE_PATH + "application/fetch", { params, ...config }),
        fetchUserActiveProjects: (params) => Axios.get(BASE_PATH + "application/details/fetch", { params, ...config }),
        verifyBankDetails: (body) => Axios.post(BASE_PATH + "profile/bank/update/status/excel", body, config),
        fetchProfile: (params) => Axios.get(BASE_PATH + "profile/basic/fetch", { params, ...config }),
        updateProfile: (body) => Axios.post(BASE_PATH + "profile/basic/update", body, config),
        uploadUsersBankDetail: (body) => Axios.post(BASE_PATH + "profile/bank/add/excel", body, config),
        uploadUsersDocuments: (body) => Axios.post(BASE_PATH + "profile/document/update/excel", body, config),
        uploadUsersPersonal: (body) => Axios.post(BASE_PATH + "profile/basic/update/excel", body, config),
        fetchBankDetails: (params) => Axios.get(BASE_PATH + "profile/bank/fetch", { params, ...config }),
        userVisibleProject: (params) => Axios.get(BASE_PATH + "job/fetch/for/user", { params, ...config }),

    },
    JobTask: {
        fetch: (params) => Axios.get(BASE_PATH + "task/fetch/detail", { params, ...config }),
        fetchBasic: (params) => Axios.get(BASE_PATH + "task/fetch", { params, ...config }),
        create: (body) => Axios.post(BASE_PATH + "task/create", body, config),
        edit: (body) => Axios.post(BASE_PATH + "task/update", body, config),
        createForm: (body) => Axios.post(BASE_PATH + "form/create", body, config),
        editForm: (body) => Axios.post(BASE_PATH + "form/edit", body, config),
        addResponse: (body) => Axios.post(BASE_PATH + "form/response/add", body, config),
        addResponseInput: (body) => Axios.post(BASE_PATH + "form/response/input/add", body, config),
        generateFormResponseURL: (body) => Axios.post(BASE_PATH + "form/response/url/generate", body, config),
        fetchAllTaskCountOfUser: (params) => Axios.get(BASE_PATH + "form/response/count/by/user/all/tasks", { params, ...config }),
        downloadFormResponse: (params) => Axios.get(BASE_PATH + "form/response/download", { params, ...config }),
        downloadFormResponseMedia: (params) => Axios.get(BASE_PATH + "form/response/media/download", { params, ...config }),
        downloadFormGroupedResponse: (params) => Axios.get(BASE_PATH + "form/download/by/status", { params, ...config }),
        addResponseByCsv: (body) => Axios.post(BASE_PATH + "form/response/upload", body, config),
        fetchTaskResp: (params) => Axios.get(BASE_PATH + "form/response/fetch", { params, ...config }),
        editTaskFormResp: (body) => Axios.post(BASE_PATH + "form/response/raw/edit", body, config),
        fetchTaskRespByForm: (params) => Axios.get(BASE_PATH + "form/response/fetch/by/form", { params, ...config }),
        fetchTaskRespWithUser: (params) => Axios.get(BASE_PATH + "task/data/fetch", { params, ...config }),
        fetchGroupedTaskResp: (params) => Axios.get(BASE_PATH + "form/response/group/fetch", { params, ...config }),
        addGroupedResponseByCsv: (body) => Axios.post(BASE_PATH + "form/response/group/upload", body, config),
        verifyResponseByCsv: (body) => Axios.post(BASE_PATH + "form/response/update/status/excel", body, config),
        verifyResponse: (body) => Axios.post(BASE_PATH + "form/response/update", body, config),
        rejectAuditRequiredResponse: (body) => Axios.post(BASE_PATH + "form/response/reject/audit/required", body, config),
        updateTaskResponseCommentByCsv: (body) => Axios.post(BASE_PATH + "form/response/update/status/comment/excel", body, config),
        uploadTargetCsv: (body) => Axios.post(BASE_PATH + "task/target/sheet/upload", body, config),
        fetchScheduling: (params) => Axios.get(BASE_PATH + "task/scheduling/fetch", { params, ...config }),
        inactiveTaskScheduling: (body) => Axios.post(BASE_PATH + "task/scheduling/inactive", body, config),
        addTaskScheduling: (body) => Axios.post(BASE_PATH + "task/scheduling/add", body, config),
        editTaskScheduling: (body) => Axios.post(BASE_PATH + "task/scheduling/edit", body, config),
        fetchScheduledUsers: (params) => Axios.get(BASE_PATH + "task/scheduling/users/fetch", { params, ...config }),
        scheduleUsers: (body) => Axios.post(BASE_PATH + "task/scheduling/users/map", body, config),
        fetchTaskCountByInputFilter: params => Axios.get(BASE_PATH + "form/status/input/filter/count", { params, ...config }),
        updateTaskAuditStatus: (body) => Axios.post(BASE_PATH + "form/response/audit-status/update", body, config),
        fetchTaggedReason: (params) => Axios.get(BASE_PATH + "task/training/media/fetch", { params, ...config }),
        editTaggedReason: (body) => Axios.post(BASE_PATH + "task/training/media/reason/update", body, config),
        addRejectionReason: (body) => Axios.post(BASE_PATH + "task/training/media/add", body, config),
        editRejectionReason: (body) => Axios.post(BASE_PATH + "task/training/media/update", body, config),
        inactiveMediaReason: (body) => Axios.post(BASE_PATH + "task/training/media/delete", body, config),
        fetchTaskInstruction: (params) => Axios.get(BASE_PATH + "task/instructions/fetch", { params, ...config }),
        updateTaskInstruction: (body) => Axios.post(BASE_PATH + "task/instructions/update", body, config),
        deleteTaskInstruction: (body) => Axios.post(BASE_PATH + "task/instructions/delete", body, config),
        syncVerificationGSheet: (params) => Axios.get(BASE_PATH + "task/verification/gsheet/sync", {...config, params }),
        fetchRejectionReason: (params) => Axios.get(BASE_PATH + "task/report/rejections", { params, ...config }),
        fetchTaskCountGraphData: (params) => Axios.get(BASE_PATH + "task/report/counts", { params, ...config }),
        fetchTaskHistoryData: (params) => Axios.get(BASE_PATH + "task/history/fetch", { params, ...config }),
    },
    JobTaskPayment: {
        fetch: (params) => Axios.get(BASE_PATH + "job/payment/fetch", { params, ...config }),
        add: (body) => Axios.post(BASE_PATH + "job/payment/add", body, config),
        sendUpdateNotification: (body) => Axios.post(BASE_PATH + "job/payment/update/notification", body, config),
        update: (body) => Axios.post(BASE_PATH + "job/payment/edit", body, config),
        delete: (body) => Axios.post(BASE_PATH + "job/payment/inactive", body, config),
        calculate: (body) => Axios.post(BASE_PATH + "payment/calculate", body, config),
        generateInvoice: (body) => Axios.post(BASE_PATH + "payment/invoice/generate", body, config),
        generatePaymentcertificate: (body) => Axios.post(BASE_PATH + "payment/certificate/generate", body, config),
        fetchInvoiceList: (params) => Axios.get(BASE_PATH + "payment/invoice/list", { params, ...config }),
        downloadPaymentInvoiceList: (params) => Axios.get(BASE_PATH + "payment/invoice/status/download", { params, ...config }),
        fetchCertificateList: (params) => Axios.get(BASE_PATH + "payment/certificate/list", { params, ...config }),
        downloadPaymentCertificateList: (params) => Axios.get(BASE_PATH + "payment/certificate/download", { params, ...config }),
        fetchPayoutDueAmount: (params) => Axios.get(BASE_PATH + "payment/payout/payable", { params, ...config }),
        payoutInit: (body) => Axios.post(BASE_PATH + "payment/payout/init", body, config),
        fetchPayouts: (params) => Axios.get(BASE_PATH + "payment/payout/fetch", { params, ...config }),
        fetchPayoutData: (params) => Axios.get(BASE_PATH + "payment/payout/data/fetch", { params, ...config }),
        fetchPayoutDataTransactions: (params) => Axios.get(BASE_PATH + "payment/payout/data/transactions/fetch", { params, ...config }),
        retryPayoutData: (body) => Axios.post(BASE_PATH + "payment/payout/data/retry", body, config),
        fetchUserPayouts: (params) => Axios.get(BASE_PATH + "payment/payout/user/fetch", { params, ...config }),
    },
    CONSTANTS: {
        downloadBankDetails: (status) => BASE_PATH + "profile/bank/download/excel?status=" + status
    },
    STORY: {
        addStory: (body) => Axios.post(BASE_PATH + "story/add", body, config),
    },
    Userwallet: {
        addWallet: (body) => Axios.post(BASE_PATH + "wallet/transaction/add/excel", body, config),
        downloadWalletDetails: (params) => Axios.get(BASE_PATH + "wallet/download", { params, ...config }),
        downloadWalletDetailsBankPayable: (params) => Axios.get(BASE_PATH + "wallet/download/bank/payable", { params, responseType: "blob", headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }, ...config }),
        downloadWalletTransaction: (params) => Axios.get(BASE_PATH + "wallet/transaction/download", { params, ...config }),
        fetchTransactions: (params) => Axios.get(BASE_PATH + "wallet/transaction/fetch", { params, ...config }),
        fetchUserBalance: (params) => Axios.get(BASE_PATH + "wallet/amount/fetch", { params, ...config }),
        fetchUserBalanceDetails: (params) => Axios.get(BASE_PATH + "wallet/user/fetch", { params, ...config }),
    },
    StoreManager: {
        addStore: (body) => Axios.post(BASE_PATH + "store/create", body, config),
        fetchStore: (params) => Axios.get(BASE_PATH + "store/list", { params, ...config }),
        fetchAllUser: (params) => Axios.get(BASE_PATH + "store/get/all/users", { params, ...config }),
        inactiveUser: (body) => Axios.post(BASE_PATH + "store/user/delete", body, config),
        resetStore: (body) => Axios.post(BASE_PATH + "store/reset", body, config),
        fetchMaterialData: (params) => Axios.get(BASE_PATH + "store/get/user/material/data", { params, ...config }),
        fetchMaterialLimits: (params) => Axios.get(BASE_PATH + "store/select/material/value", { params, ...config }),
        addMaterialLimits: (body) => Axios.post(BASE_PATH + "store/insert/material/value", body, config),
        editMaterialLimits: (body) => Axios.post(BASE_PATH + "store/edit/material/value", body, config),
        dispatchMaterial: (body) => Axios.post(BASE_PATH + "store/dispatch/material", body, config),
        edit: (body) => Axios.post(BASE_PATH + "store/edit/material/data", body, config),
        fetchAllTransaction: (params) => Axios.get(BASE_PATH + "store/get/transaction", { params, ...config }),
        uploadTask: (body) => Axios.post(BASE_PATH + "external/user/task/upload", body, config),
        uploadUsers: (body) => Axios.post(BASE_PATH + "external/user/upload", body, config),
        addMaterialItem: (body) => Axios.post(BASE_PATH + "/store/insert/material/description", body, config),
        editMaterialItem: (body) => Axios.post(BASE_PATH + "/store/edit/material/description", body, config),
        fetchMaterialItem: (params) => Axios.get(BASE_PATH + "store/select/material/description", { params, ...config }),
        editIssuedMaterial: (body) => Axios.post(BASE_PATH + "store/edit/material/data", body, config),
        addMaterialInput: (body) => Axios.post(BASE_PATH + "store/input", body, config),
        fetchMaterialInputTransactions: (params) => Axios.get(BASE_PATH + "store/fetch/input/transactions", { params, ...config }),
        editMaterialInputTransactions: (body) => Axios.post(BASE_PATH + "store/edit/input/transaction", body, config),
        fetchMaterialInputCount: (params) => Axios.get(BASE_PATH + "store/material/left", { params, ...config }),
        downloadMaterial: (params) => Axios.get(BASE_PATH + "store/material/download", { params, ...config }),
        downloadTransaction: (params) => Axios.get(BASE_PATH + "store/transaction/download", { params, ...config }),
        storeTransfer: (body) => Axios.post(BASE_PATH + "store/to/store/transfer", body, config),
        uploadMaterial: (body) => Axios.post(BASE_PATH + "store/material/upload", body, config),
        uploadMaterialConsumption: (body) => Axios.post(BASE_PATH + "store/consumption/upload", body, config),
        downloadSampleMaterialConsumption: (params) => Axios.get(BASE_PATH + "store/material/template/download", { params, ...config }),
        downloadSampleUploadMaterial: (params) => Axios.get(BASE_PATH + "store/material/template/download", { params, ...config }),
    },
    InterviewForm: {
        addInterviewer: (body) => Axios.post(BASE_PATH + "application/assessment/insert", body, config),
        fetchInterviewer: (params) => Axios.get(BASE_PATH + "application/assessment/get/all", { params, ...config }),
        fetchUser: (params) => Axios.get(BASE_PATH + "application/assessment/get/user", { params, ...config }),
        editAssessment: (body) => Axios.post(BASE_PATH + "application/assessment/edit", body, config),

    },
    Referral: {
        fetchReferralCode: (params) => Axios.get(BASE_PATH + "referral/fetch", { params, ...config }),
        fetchReferral: (params) => Axios.get(BASE_PATH + "referral/get/all", { params, ...config }),
        rejectReferral: (body) => Axios.post(BASE_PATH + "referral/tracking/reject", body, config),
        downloadReferral: (params) => Axios.get(BASE_PATH + "referral/download/excel", { params, ...config }),
        getCountByChannelAndDate: (params) => Axios.get(BASE_PATH + "referral/get/group/counts", { params, ...config }),
        getReferralsByChannel: (params) => Axios.get(BASE_PATH + "referral/fetch/referred/by/refer", { params, ...config }),
        fetchReferralDetails: (params) => Axios.get(BASE_PATH + "referral/fetch/referred", { params, ...config }),
    },
    Dashboard: {
        fetchInTaskUsers: (params) => Axios.get(BASE_PATH + "dashboard/users/in-task", { params, ...config }),
        fetchTaskGraphData: (params) => Axios.get(BASE_PATH + "dashboard/graph/data", { params, ...config }),
        fetchCount: (params) => Axios.get(BASE_PATH + "attendance/get/count", { params, ...config }),
        getAttendanceUsersByStatus: (params) => Axios.get(BASE_PATH + "attendance/get/user/by/status", { params, ...config }),
        fetchAttendance: (params) => Axios.get(BASE_PATH + "attendance/check/for/user", { params, ...config }),
        fetchUserTaskData: (params) => Axios.get(BASE_PATH + "dashboard/user/task/data", { params, ...config }),
        fetchUserLocationData: (params) => Axios.get(BASE_PATH + "dashboard/user/location/data", { params, ...config }),
        fetchNewActivatedUser: (params) => Axios.get(BASE_PATH + "form/activated/users", { params, ...config }),
        fetchEarnedPartners: (params) => Axios.get(BASE_PATH + "form/status/earned", { params, ...config }),
    },
    Notification: {
        fetchScheduled: (params) => Axios.get(BASE_PATH + "notification/get/scheduled", { params, ...config }),
        addScheduled: body => Axios.post(BASE_PATH + "notification/schedule", body, config),
        deleteScheduled: body => Axios.post(BASE_PATH + "notification/delete/scheduled", body, config),
        editScheduled: body => Axios.post(BASE_PATH + "notification/edit/scheduled", body, config),
        sendSMSByTemplate: body => Axios.post(BASE_PATH + "notification/sms/template/send", body, config),
        fetchPendingActions: (params) => Axios.get(BASE_PATH + "common/user/pending/counts", { params, ...config }),
        fetchPendingActionsData: (params) => Axios.get(BASE_PATH + "common/user/pending/fetch", { params, ...config }),
        fetchPendingNotification: (params) => Axios.get(BASE_PATH + "notification/fetch", { params, ...config }),
        updateNotification:(body) => Axios.post(BASE_PATH + "notification/publish/update", body, config)
    },
    Calling: {
        connectOutboundCall: (body) => Axios.post(BASE_PATH + "calling/connect", body, config),
        fetchOutboundCall: (params) => Axios.get(BASE_PATH + "calling/enquiry", { params, ...config }),
        fetchOutboundCallStatus: (params) => Axios.get(BASE_PATH + "calling/fetch/status", { params, ...config }),
        updateCallData: (body) => Axios.post(BASE_PATH + "calling/feedback/update", body, config),
        fetchDetails: (params) => Axios.get(BASE_PATH + "calling/fetch/details", { params, ...config }),
        fetchDetailsByUser: (params) => Axios.get(BASE_PATH + "calling/fetch/user/details", { params, ...config }),
        fetchIncomingCalls: (params) => Axios.get(BASE_PATH + "calling/incoming/fetch", { params, ...config }),
        fetchInboundStatus: (params) => Axios.get(BASE_PATH + "callers/status", {params, ...config}),
        updateInboundStatus: (body) => Axios.post(BASE_PATH + "callers/status/update", body, config),
        fetchCallers: (params) => Axios.get(BASE_PATH + "callers/fetch", {params, ...config}),
        removeCaller: (body) => Axios.post(BASE_PATH + "callers/remove", body, config),
        updateCaller: (body) => Axios.post(BASE_PATH + "callers/update", body, config),
        connectOutboundCustomerCall: (body) => Axios.post(BASE_PATH + "calling/customer/connect", body, config),
        fetchOutboundCustomerCall: (params) => Axios.get(BASE_PATH + "calling/customer/enquiry", { params, ...config }),
        updateCallCustomerData: (body) => Axios.post(BASE_PATH + "calling/customer/feedback/update", body, config),
        fetchCustomerCallDetails: (params) => Axios.get(BASE_PATH + "calling/customer/fetch/details", { params, ...config }),
    },
    Rating: {
        fetchAggregated: (params) => Axios.get(BASE_PATH + "rating/fetch/aggregated", { params, ...config })
    },
    Templates: {
        fetchSmsTemplates: (params) => Axios.get(BASE_PATH + "template/sms/fetch", { params, ...config })
    },
    TrainingQuestion: {
        fetchTrainingQuestion: (params) => Axios.get(BASE_PATH + "job/training/question/fetch", { params, ...config }),
        addTrainingQuestion: body => Axios.post(BASE_PATH + "job/training/question/add", body, config),
        deleteTrainingQuestion: body => Axios.post(BASE_PATH + "job/training/question/inactive", body, config),
        editTrainingQuestion: body => Axios.post(BASE_PATH + "job/training/question/edit", body, config),
        addQuestionTiming: body => Axios.post(BASE_PATH + "job/media/training/question/percentage/add", body, config),
        editQuestionTiming: body => Axios.post(BASE_PATH + "job/media/training/question/percentage/edit", body, config),
    },
    Handshake: {
        fetchPendingHandshake: (params) => Axios.get(BASE_PATH + "handshake/fetch/pending", { params, ...config }),
        fetchCompletedHandshake: (params) => Axios.get(BASE_PATH + "handshake/fetch/completed", { params, ...config }),
        add: body => Axios.post(BASE_PATH + "handshake/add", body, config),
    },
    Whatsapp: {
        fetchConversation: (params) => Axios.get(BASE_PATH + "whatsapp/conversation/fetch", { params, ...config }),
        fetchConversationThreads: (params) => Axios.get(BASE_PATH + "whatsapp/conversation/threads", { params, ...config }),
        addConversation: body => Axios.post(BASE_PATH + "whatsapp/conversation/add", body, config),
        handoverConversation: body => Axios.post(BASE_PATH + "whatsapp/conversation/handover", body, config),
        fetchConversationByEntity: params => Axios.get(BASE_PATH + "whatsapp/conversation/list/fetch/by/entity", { params, ...config }),

    },
    Events: {
        fetchEvents: (params) => Axios.get(BASE_PATH + "events/fetch/all", { params, ...config }),
        createEvent: body => Axios.post(BASE_PATH + "events/create", body, config),
        inactiveEvent: body => Axios.post(BASE_PATH + "events/inactive", body, config),
        fetchEventAttendees: (params) => Axios.get(BASE_PATH + "events/attendees/fetch", { params, ...config }),
        fetchEventFeedback: (params) => Axios.get(BASE_PATH + "events/feedback/fetch", { params, ...config }),
        insertEventAttendees: body => Axios.post(BASE_PATH + "events/attendees/create", body, config),
        updateEventAttendees: body => Axios.post(BASE_PATH + "events/attendees/update", body, config),
        createNewAttendees: body => Axios.post(BASE_PATH + "events/add/attendee", body, config),
        fetchWebinarReport: (params) => Axios.get(BASE_PATH + "events/fetch/attendees/count", { params, ...config }),
        fetchEventAttendeesFunnel: (params) => Axios.get(BASE_PATH + "events/attendees/funnel", { params, ...config })
    },
    Feedback: {
        addSuggestion: body => Axios.post(BASE_PATH + "feedback/suggestion/add", body, config),
    },
    Customer: {
        uploadLeads: body => Axios.post(BASE_PATH + "customer/leads/excel/upload", body, config),
        fetchLeads: params => Axios.get(BASE_PATH + "customer/leads/fetch", {...config, params }),
        sendJobInvite: body => Axios.post(BASE_PATH + "customer/leads/project/invite/send", body, config),
        updateCustomerJobStatus: body => Axios.post(BASE_PATH + "customer/leads/status/update", body, config),
        customerDetails: params => Axios.get(BASE_PATH + "customer/leads/data/fetch", {...config, params }),
        customerCallingDetails: params => Axios.get(BASE_PATH + "calling/fetch/user/details", {...config, params }),
        customerProjectDetails: params => Axios.get(BASE_PATH + "customer/leads/added/jobs/fetch", {...config, params }),
    },
    Portfolio: {
        fetchAccessiblePortfolio: params => Axios.get(BASE_PATH + "portfolio/access/fetch", {...config, params }),
        fetchAccessDetails: params => Axios.get(BASE_PATH + "portfolio/access/detail", {...config, params }),
        addAccess: body => Axios.post(BASE_PATH + "portfolio/access/add", body, config),
        removeAccess: body => Axios.post(BASE_PATH + "portfolio/access/inactive", body, config),
        fetchToBeMappedUsers: params => Axios.get(BASE_PATH + "portfolio/users/fetch/unassigned", {...config, params }),
        fetchMappedUsers: params => Axios.get(BASE_PATH + "portfolio/users/fetch", {...config, params }),
        mapPartnerToPortfolio: body => Axios.post(BASE_PATH + "portfolio/users/map", body, config),
        removePartnerFromPortfolio: body => Axios.post(BASE_PATH + "portfolio/users/inactive", body, config),
        fetchTaskCounts: params => Axios.get(BASE_PATH + "portfolio/fetch/counts", {...config, params }),
        fetchMappedPortfolio: params => Axios.get(BASE_PATH + "portfolio/users/mapped/portfolio", {...config, params }),
        createPortfolio: body => Axios.post(BASE_PATH + "portfolio/add", body, config),
        editPortfolio: body => Axios.post(BASE_PATH + "portfolio/edit", body, config),
        deletePortfolio: body => Axios.post(BASE_PATH + "portfolio/inactive", body, config),

    },
    ToBeActivePartner: {
        fetchInterestedUser: params => Axios.get(BASE_PATH + "common/fetch/interested/user/to/be/activated", {...config, params }),
        fetchToBeActiveUser: params => Axios.get(BASE_PATH + "common/fetch/user/to/be/activated", {...config, params }),
        fetchFirstTaskAttemptedUser: params => Axios.get(BASE_PATH + "common/fetch/user/task/attempted", {...config, params }),
    },
    Support: {
        fetchTicketCounts: params => Axios.get(BASE_PATH + "support/agent/ticket/fetch/counts", {...config, params }),
        fetchTickets: params => Axios.get(BASE_PATH + "support/agent/ticket/fetch", {...config, params }),
        fetchTicketConversation: params => Axios.get(BASE_PATH + "support/agent/ticket/conversation/fetch", {...config, params }),
        createTicket: body => Axios.post(BASE_PATH + "support/agent/ticket/create", body, config),
        updateTicket: body => Axios.post(BASE_PATH + "support/agent/ticket/update", body, config),
        updateTicketResolved: body => Axios.post(BASE_PATH + "support/agent/ticket/update/resolved", body, config),
        transferTicket: body => Axios.post(BASE_PATH + "support/agent/ticket/transfer", body, config),
        fetchTeamMembers: params => Axios.get(BASE_PATH + "support/agent/team/fetch",{params, ...config}),
        sendChat: body => Axios.post(BASE_PATH + "support/agent/ticket/chat/add", body, config),

        fetchUserFormResponse: params => Axios.get(BASE_PATH + "support/user/task/fetch",{params, ...config}),
        fetchUserRechargeList: params => Axios.get(BASE_PATH + "support/user/recharge/fetch",{params, ...config}),


    },
    ServicePurchase: {
        fetchData: params => Axios.get(BASE_PATH + "service/report/data", {...config, params }),
        raiseDispute: body => Axios.post(BASE_PATH + "service/mobile/order/dispute", body, config),
    },
    Invoice: {
        fetchClients: params => Axios.get(BASE_PATH + "client/fetch", {...config, params }),
        addClient: body => Axios.post(BASE_PATH + "client/add", body, config),
        editClient: body => Axios.post(BASE_PATH + "client/update", body, config),

        fetchInvoice: params => Axios.get(BASE_PATH + "client/invoice/fetch/all", {...config, params }),
        fetchInvoiceByDateRange: params => Axios.get(BASE_PATH + "client/invoice/fetch/date-range", {...config, params }),
        addInvoice: body => Axios.post(BASE_PATH + "client/invoice/add", body, config),
        sendInvoiceMail: body => Axios.post(BASE_PATH + "client/invoice/send", body, config),
        cancelInvoice: body => Axios.post(BASE_PATH + "client/invoice/cancel", body, config),
        generateInvoiceIRN: body => Axios.post(BASE_PATH + "client/invoice/irn/generate", body, config),
        markPaidInvoice: body => Axios.post(BASE_PATH + "client/invoice/paid", body, config),
    
    },
    MyTeamUserDetails: {
        fetchCountDetails: params => Axios.get(BASE_PATH + "external/user/team/counts/fetch", {...config, params }),
    },
    UrlShortener : {
        getLink: params => Axios.get(BASE_PATH + "url/data/fetch", {...config, params }),
    },
    CampaignFilter : { 
        userFilters: body => Axios.post(BASE_PATH + "campaign/user/filter", body, config),
        createCampaign: body => Axios.post(BASE_PATH + "campaign/create", body, config),
        scheduleCampagin: body => Axios.post(BASE_PATH + "campaign/schedule/create", body, config),
        fetchCampaigns: params => Axios.get(BASE_PATH + "campaign/fetch", {...config, params }),
        sendTestEmail: body => Axios.post(BASE_PATH + "campaign/email/test", body, config),
        sendTestWhatsapp: body => Axios.post(BASE_PATH + "campaign/whatsapp/test", body, config),

    },
    Marketing: {
        fetchBanners: params => Axios.get(BASE_PATH + "static/promotions/fetch/all", {...config, params }),
        createBanner: body => Axios.post(BASE_PATH + "static/promotions/create", body, config),
        updateBanner: body => Axios.post(BASE_PATH + "static/promotions/update", body, config),
        deleteBanner: body => Axios.post(BASE_PATH + "static/promotions/delete", body, config)
    }
};

export default Endpoints;