import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse,} from 'reactstrap';
import {Button, Modal, ModalBody,ModalHeader,ModalFooter} from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select"
import InputFeild from "../../global/form/input";
import moment from "moment";
import CONSTANTS from "../../../constants";
import ConfirmAction from "../../global/confirm-action";
import DatePicker from "react-datepicker";

import OutboundCallingStatus from "../../global/outbound-calling-status";
import Table from "reactstrap/lib/Table";

import { Badge, ButtonGroup, Chip, Popover, TablePagination } from "@material-ui/core";
import UpdateApplicationState from "./update_application_state";
import AddLeads from "./add_leads";
import UploadLeads from "./upload_leads";
import PartnerDetail from "../user_profile";
import WhatsappChat from "../../global/whatsapp_chat";
import { withRouter } from "react-router";
import { groupArrayOnKey, downloadApiFile, uploadFile, accessExotelCallRecording } from "../../../actions";
import { Switch } from "@material-ui/core";
import { TrackEvent } from "../../../actions/ga"
import UserCard from "../user_profile/user_card";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import RichTextEditor from "../../global/form/Richtext";


class RecruitmentComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
            isConfirm : false,
            isConfirmMessage : null,

            jobList: this.props.jobList || [],
            selectedJobId: this.props.selectedJobId,
            selectedJob: this.props.selectedJob,
            selectedJobLocations: this.props.selectedJobLocations || [],
            selectedJobLocationId: this.props.selectedJobLocationId,

            selectedState: this.props.selectedState || "LEAD",
            applicantList: [],
            isOpenNotificationModal: false,
            isOpenComment: false,
            // callDetails : {user: {first_name: "Anshul gg", user_id: "123456"}, calling_id: 1234567},
            callDetails : false,
            searchText : "",
            isOpenUpdateModal : false,
            addLead: false,
            isOpenCardModel: false,
            changeApplicationLocation: false,
            applicantRowsPerPage: 50,
            applicantCurrentPage : 0,
            whatsappChat: false,
            isShowExtraDetails: null,
            jobExtraDetails: [],
            isUploadExtraDetails: false,
            isDownloadExtraDetails: false,
            isShowExtraInputs: false,
            callData: [],
            transitionData: [],
            isShowSystemLead : false,
            isFilters: false,
            isOpenHotLeadPopOver: null,
            isOpenWarningDetails: false,
            warningContent: null,
        }
      }

      
    async componentDidMount(){
        this.fetchJobExtraDetails();
        this.fetchWorkOrder();
        await this.getApplicants();
    }

    
          
    fetchJobExtraDetails = async () =>{
        // store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.JobExtraDetails.fetch({job_id : this.state.selectedJobId});
            this.setState({jobExtraDetails : apiData.data.data}) ;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        // store.dispatch(setLoader(false))
    }

    fetchWorkOrder = async () => {
        // store.dispatch(setLoader(true))
        try{
           let resp = await ApiEndpoints.Jobs.getWorkOrder({job_id :this.props.selectedJobId });
           let workOrderList = resp.data.data;
           this.setState({workOrderList})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        // store.dispatch(setLoader(false))
    }

    getApplicants = async (selectedState) => {
        let {selectedJobId, selectedJobLocationId} = this.state;
        this.setState({applicantList: []});
        const query = new URLSearchParams(this.props.location.search);
        if(!selectedState) selectedState = this.props.selectedState || query.get("status");
        else {
            query.set("status", selectedState);
            this.props.history.push(this.props.match.url+"?"+query.toString())
        }
        try{
            store.dispatch(setLoader(true));

            var body = { job_id: selectedJobId };

            if(selectedJobLocationId) body.job_location_id = this.state.selectedJobLocationId;
            let apiData;
            if(selectedState === "LEADS" && this.state.isShowSystemLead){
                apiData = await ApiEndpoints.Applicants.anotherProjectsPartnerLeads(body);
            }
            else{
                if(selectedState === "PENDING_ACTION") body = {...body, is_stale: "0", onlyPendingAction: true, include_open_conversation: true};
                else if(selectedState === "INACTIVE") body.is_stale= "1";
                else body = {...body, is_stale: "0", status: selectedState, include_score: 1};

                if(selectedState === "IN_TASK") body = {...body, taskCountFilter: [0, 0]}
                if(selectedState === "IN_TASK_1") body = {...body, status: "IN_TASK", taskCountFilter: [1, 4], include_score: 0}
                if(selectedState === "IN_TASK_5") body = {...body, status: "IN_TASK", taskCountFilter: [5, 9], include_score: 0}
                if(selectedState === "IN_TASK_10") body = {...body, status: "IN_TASK", taskCountFilter: [10], include_score: 0}
                apiData = await ApiEndpoints.Applicants.fetch(body);
            }   
        this.setState({applicantList: apiData.data.data, selectedState, applicantCurrentPage: 0, isShowExtraDetails: null, transitionData: []})

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    searchApplicant = (e) => {
        e.preventDefault();
        let { searchText } = this.state;
        searchText = e.target.search.value;
        this.setState({ searchText })
    }

    

    showApplicationComment = async (applicant) => {
        store.dispatch(setLoader(true));
        try {
            var body = {
                application_id : applicant.application_id
            }
            var resp = await ApiEndpoints.Applicants.fetchComment(body);
            this.setState({isOpenComment : {data: resp.data.data, applicant}});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        let { isOpenNotificationModal } = this.state;;
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : isOpenNotificationModal.user_id,
            notification_type : e.target.notification_type.value,
            origin_type : "JOB",
            origin_id : this.state.selectedJobId,
            sms : false,
            redirection_screen: isOpenNotificationModal.originType,
            // sms : e.target.sms.checked
        } 
        store.dispatch(setLoader(true))
        try{
            if(["STAGES", "JOB_TASK", "TASK_LEADERBOARD"].indexOf(isOpenNotificationModal.originType) !== -1){
                if(!isOpenNotificationModal.job_id) throw new Error("Select Project for redirection");
                body.redirection_entity_type = "JOB";
                body.redirection_entity_id = isOpenNotificationModal.job_id;
            }

            if(["JOB_TASK", "TASK_LEADERBOARD"].indexOf(isOpenNotificationModal.originType) !== -1){
                if(!isOpenNotificationModal.job_task_id) throw new Error("Select Project Task for redirection");
                body.redirection_sub_entity_type = "JOB_TASK";
                body.redirection_sub_entity_id = isOpenNotificationModal.job_task_id;
            }
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }


    addApplicationComment = async (e) => {
        e.preventDefault();
        let { isOpenComment } = this.state;
        store.dispatch(setLoader(true));
        try {
            const statusValue = {};
            Object.keys(CONSTANTS.APPLICATION_STATUS).forEach(key => {
                statusValue[CONSTANTS.APPLICATION_STATUS[key]] = key;
            });
            var body = {
                application_id : isOpenComment.applicant.application_id,
                comment: e.target.comment.value,
                state: statusValue[isOpenComment.applicant.status]
            }
            await ApiEndpoints.Applicants.addComment(body);
            this.showApplicationComment(isOpenComment.applicant);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    makePhoneCall = async (applicant, status) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : applicant.user_id,
                entity_type: "JOB",
                subentity_type: "APPLICATION_STATUS",
                entity_id: this.state.selectedJobId,
                subentity_id: applicant.status,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: applicant.User,
                // ...(status === 'APPLIED' ? {
                //     failure_message: `PickMyWork HR tried reaching out to you for your interview but you were unavailable. Please call back at +91${ store.getState().user.mobile} for your interview for Project - ${this.state.selectedJob.Employer.employer_name +  "  -  "  + this.state.selectedJob.title}`,
                //     template_id: "1207161722501608603"
                // }:{})
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }

    toggleOpenModal = async(user, curr_state, state, work_order_id) => {
        let isUpdate = false;
        if(state === "SHORTLISTED_UPDATE"){
            isUpdate = true;
            state = "SHORTLISTED";
        }
        this.setState({isOpenUpdateModal: (user ? {user, curr_state, state, work_order_id, isUpdate, job_id : this.state.selectedJobId} : null) });
    }


    changeApplicationLocation = async () => {
        TrackEvent("RECRUITMENT_TAB", "CHANGE_PARTNER_PROJECT_LOCATION", "PARTNER");

        let { changeApplicationLocation, applicantList } = this.state;
        store.dispatch(setLoader(true));
        try{
            if(!changeApplicationLocation.application_id || !changeApplicationLocation.job_location_id)
            throw new Error("Mandatory values missing");
            var body = {
                application_id : changeApplicationLocation.application_id,
                job_location_id: changeApplicationLocation.job_location_id,
                curr_job_location_id: changeApplicationLocation.curr_job_location_id
            }
            await ApiEndpoints.Applicants.changeLocation(body);
            if(this.state.selectedJobLocationId) applicantList = applicantList.filter(app => app.application_id !== changeApplicationLocation.application_id);
            this.setState({applicantList, changeApplicationLocation: false})
            store.dispatch(setSuccess({show: true, message: "Location updated successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    resetInterviewSlot = async (user_id) => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                user_id : user_id,
                job_id : this.state.selectedJobId
            } 
            await ApiEndpoints.InterviewSlot.resetSlot(body);
            this.getApplicants("APPLIED");
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    markWAMessageRead = (user) => {
        let { applicantList } = this.state;
        applicantList.map(applicant => {
            if(applicant.user_id === user.user_id) applicant.isPendingWhatsapp = false;
            return applicant;
        });
        this.setState({applicantList});
    }

    showUserExtraDetails = async (applicant) => {
        TrackEvent("RECRUITMENT_TAB", "SHOW_APPLICANT_EXTRA_DETAILS", "PARTNER");
        this.setState({userTaskCount: [], userRating: 0, isShowExtraDetails: applicant.user_id})
        if(this.state.isShowExtraDetails === applicant.user_id){
            this.setState({isShowExtraDetails: null});
            return;
        }
        store.dispatch(setLoader(true));
        let { userTaskCount } = this.state;
        try {
            var body = {
                created_by : applicant.user_id,
                user_id : applicant.user_id,
            }
            var promiseResp = await Promise.all([
                ApiEndpoints.JobTask.fetchAllTaskCountOfUser(body),
                ApiEndpoints.Rating.fetchAggregated(body),
                ApiEndpoints.Applicants.fetchApplicationTransitions({application_id: applicant.application_id, include_users: true})
            ]);
            var ratingResp = promiseResp[1].data.data;
            userTaskCount = promiseResp[0].data.data;
            var transitionData = promiseResp[2].data.data.sort((a, b) => new Date(a.created_on).getTime() > new Date(b.created_on).getTime() ? -1 : 1);

            this.setState({userTaskCount, transitionData, userRating : ratingResp[0] ? ratingResp[0].rating : 0});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    UpdateExtraDetailStatus = async (applicantData) => {
        TrackEvent("APPLICANT_EXTRA_DETAILS", "DETAILS_REQUEST_INITIATED", "EXTRA_DETAILS(IDS)");
        store.dispatch(setLoader(true))
        let { applicantList } = this.state;
        try {
            var body = {
                job_id : applicantData.job_id,
                user_id : applicantData.user_id,
                extra_details_status : "REQUEST_INITIATED"
            } 
            await ApiEndpoints.JobExtraDetails.updateStatus(body);
            applicantList = applicantList.map(applicant => {
                if(applicant.application_id === applicantData.application_id)
                    applicant.extra_details_status = 1;
                return applicant;
            })
            this.setState({applicantList});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    openDownloadExtraDetails = async (isOpen) => {
        if(!isOpen) return this.setState({isDownloadExtraDetails : false})
        store.dispatch(setLoader(true));
        let { isDownloadExtraDetails } = this.state;
        try {
            var body = {
                job_id: this.state.selectedJobId,
                job_location_id: this.state.selectedJobLocationId,
            };
            if(isDownloadExtraDetails.start_date) body.start_date = moment(isDownloadExtraDetails.start_date).startOf("day").toDate()
            if(isDownloadExtraDetails.end_date) body.end_date = moment(isDownloadExtraDetails.end_date).endOf("day").toDate()
            var resp = await ApiEndpoints.JobExtraDetails.fetchCounts(body);
            this.setState({isDownloadExtraDetails : {...isDownloadExtraDetails, countsData: resp.data.data} });
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadExtraDetails = async (type) => {
        store.dispatch(setLoader(true));
        try {
            let { isDownloadExtraDetails } = this.state;
            var body = {
                job_id: this.state.selectedJobId,
                job_location_id: this.state.selectedJobLocationId,
                extra_details_status: type
            };
            if(isDownloadExtraDetails.start_date) body.start_date = moment(isDownloadExtraDetails.start_date).startOf("day").toDate()
            if(isDownloadExtraDetails.end_date) body.end_date = moment(isDownloadExtraDetails.end_date).endOf("day").toDate()
        
            var resp1 = await ApiEndpoints.JobExtraDetails.downloadData(body)
            downloadApiFile(resp1, `${type} Extra Details.csv`)
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    uploadExtraDetails = async (e) =>{
        TrackEvent("APPLICANT_EXTRA_DETAILS", "DETAILS_DATA_UPLOADED", "EXTRA_DETAILS(IDS)");
        e.preventDefault();

        var eventName = e.nativeEvent.submitter.name;

        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                job_id : this.state.selectedJobId
            }
            let resp = eventName === "uploadRequested" ? await ApiEndpoints.JobExtraDetails.uploadSubmittedData(body) : await ApiEndpoints.JobExtraDetails.uploadData(body)
            this.setState({isUploadExtraDetails : false, isUpdateExtraDetails: false});
            await this.getApplicants();
            store.dispatch(setSuccess({show: true, message: "Records updated"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    showExtraInputs = async (applicant) =>{
        store.dispatch(setLoader(true))
        let { jobExtraDetails } = this.state;
        try{
            var body = {
                user_id : applicant.user_id,
                job_extra_detail_id: jobExtraDetails.map(o => o.job_extra_detail_id)
            }
            let resp = await ApiEndpoints.JobExtraDetails.fetchUserData(body)
            this.setState({isShowExtraInputs : resp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }


    showCallData = async (applicant) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                entity_type : "JOB",
                entity_id : this.state.selectedJobId,
                // status: "completed",
                user_id: applicant.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeSystemLead = async (isOn) => {
        let { isShowSystemLead } = this.state; 
        isShowSystemLead = isOn;
        await this.setState({isShowSystemLead});
        this.getApplicants("LEADS")

    }

    fetchJobTaskForNotification = async (job_id) => {
        store.dispatch(setLoader(true));
        let { isOpenNotificationModal } = this.state;
        try{
            let apiData = await ApiEndpoints.JobTask.fetch({job_id});
            isOpenNotificationModal.taskData = apiData.data.data;
            await this.setState({ isOpenNotificationModal });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    toggleFilter = async () => {
        let { isFilters } = this.state;
        if(isFilters) isFilters = false;
        else isFilters = {};
        this.setState({isFilters});
    }


    showHotLeadPopOver = (e, scoreData) => {
        this.setState({ isOpenHotLeadPopOver: scoreData.length ? {el: e.currentTarget, data: scoreData}:null })
    }

    sendWarningLetterDetails = async () => {
        store.dispatch(setLoader(true)) 
        let{warningContent, isOpenWarningDetails} = this.state;
        try {
            var body = {
                user_id: isOpenWarningDetails.user_id,
                job_id: this.state.selectedJobId,
                comment: warningContent,
            }
            await ApiEndpoints.Applicants.sendWarningLetter(body)
            this.setState({ warningContent: null, isOpenWarningDetails: false})
            store.dispatch(setSuccess({show: true, message: "Warning Send Successfully"}))
        } catch (e) {
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { isConfirm, jobList, selectedJobLocations, selectedJob, selectedState, applicantList, isOpenComment, searchText, addLead, changeApplicationLocation, isUploadExtraDetails, isShowExtraInputs, isDownloadExtraDetails, isOpenNotificationModal, isFilters, isOpenHotLeadPopOver, isOpenWarningDetails, warningContent} =this.state;
        if(searchText){
            applicantList = applicantList.filter(applicant => 
                (applicant.User && applicant.User.first_name ? applicant.User.first_name.toLowerCase():"").includes(searchText.toLowerCase())
                    ||
                    (applicant.User && applicant.User.mobile ? applicant.User.mobile : "").toString().includes(searchText.toLowerCase())
                    ||
                    (applicant.User && applicant.User.id ? ("PMW"+applicant.User.id) : "PMW").toLowerCase().includes(searchText.toLowerCase())
            )
        }
        let jobListById = this.state.isShowExtraDetails ? groupArrayOnKey(jobList, "job_id", true) : {};
        let jobLocationListById = groupArrayOnKey(selectedJobLocations, "job_location_id", true);
        let user = store.getState().user;
        return (
       <div>
                        
            <Row noGutters className="border-bottom border-top w-100 border" style={{overflowX: "scroll"}}>
                <Col md={12} xs={12}>
                    <ButtonGroup variant="text" aria-label="text button group">
                        {/* <Button className="pr-3" onClick={() => this.getApplicants("PENDING_ACTION")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(selectedState === "PENDING_ACTION" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link"><Icon style={{fontSize: 14}} className="align-middle">pending_actions</Icon>ToDo Action</Button> */}
                        {
                            Object.keys(CONSTANTS.APPLICANTS_STATES).map((statusKey, i) => 
                                <Button key={i} className="px-3" onClick={() => this.getApplicants(statusKey)} style={{ fontWeight: "bold", fontSize: 14, color: "#000", textDecoration: "none", ...(selectedState === statusKey ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">{CONSTANTS.APPLICANTS_STATES[statusKey].name}</Button>
                            )
                        }
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="py-1 mt-1 align-items-center">
                <Col xs={12} md={8} >
                    <span className="font-weight-bold ml-3 text-secondary">Applicants ({applicantList.length})</span>
                    {selectedState === "LEADS" && this.state.selectedJobId && <Button className="ml-2 buttonred py-1" onClick={() => this.setState({ addLead: addLead ? false : 1 })} style={{fontSize: 10}} >Add Lead</Button>}
                    {selectedState === "LEADS" && this.state.selectedJobId && <Switch size="small" color="secondary" onChange={(e) => this.onChangeSystemLead(e.target.checked)} checked={this.state.isShowSystemLead} />}
                    {selectedState === "LEADS" && ( this.state.isShowSystemLead ? "Show Added Leads" : "Show System Leads")}
                    {(selectedState.includes("IN_TASK") || selectedState.includes("IN_TRAINING")) && !!this.state.jobExtraDetails.length && store.getState().user.UserRole.filter(role =>  [3,1].includes(role.role_id)).length ?
                        <>
                            <Button className="ml-2 buttonred py-1" onClick={() => this.openDownloadExtraDetails(true)} style={{fontSize: 10}} >Download IDs</Button>
                            <Button className="ml-2 buttonred py-1" onClick={() => this.setState({ isUploadExtraDetails: true })} style={{fontSize: 10}} >Upload IDs</Button>
                         </>
                    : ""}
                </Col>
                <Col xs={10} md={3}>
                    <form className="" onSubmit={(e) => this.searchApplicant(e) } >
                        <InputFeild style={{fontSize: 12}} name="search" icon="search" placeholder="Search by type and press enter" />
                    </form>
                </Col>
                <Col xs={"auto"}>
                    {/* <Icon className="align-middle" onClick={() => this.toggleFilter()} style={{fontSize: 20, cursor: true}}>filter_alt</Icon> */}
                </Col>
            </Row>
            <Row>
                <Col>
                <Collapse isOpen={!!isFilters}>
                    <Row className="justify-content-end">
                        <Col xs={12} lg={6} className="border mx-3 mb-3">
                            <SelectList className="mt-2" placeholder="Select Call Type" value={isFilters.direction || ""} onChange ={(e) => {isFilters.direction = e.target.value; this.setState({isFilters})}} >
                            {[{direction: 1, label: "Incoming"}, {direction:  2, label: "Outgoing"}].map(obj => 
                                <option key={obj.direction} value={obj.direction}>{obj.label}</option>
                            )}
                            </SelectList>
                            <Row className={"mt-3"}>
                                <Col>
                                    <DatePicker
                                        selected={isFilters.start_date || null}
                                        onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                                        isClearable
                                        dateFormat='dd-MMM-yyyy'
                                        placeholderText="Start Date"
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={isFilters.end_date || null}
                                        onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                                        isClearable
                                        dateFormat='dd-MMM-yyyy'
                                        placeholderText="End Date"
                                    />
                                </Col>
                            </Row>
                            <div className="text-right py-3"><Button color="dark" size="sm" onClick={() => this.selectTab()}>Apply Filter</Button></div>
                        </Col>
                    </Row>
                </Collapse>
                </Col>
            </Row>
            {
                applicantList.length ? applicantList.slice(this.state.applicantCurrentPage*this.state.applicantRowsPerPage, this.state.applicantCurrentPage*this.state.applicantRowsPerPage+this.state.applicantRowsPerPage).map((applicant, i) => {
                    let score = (applicant.ApplicationScores || []).reduce((prev, curr) => prev + parseInt(curr.score_value) , 0);
                    let maxScore = 1200;
                    let hotP = score*100/maxScore;
                    return <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                        <Col md={3} xs={12}>
                            <Row noGutters className="align-items-center">
                                <Col className="align-items-center">
                                    <UserCard data={applicant.User} />
                                    {/* <p className="mb-0" style={{fontSize: 11}}>
                                        Location : 
                                            {applicant.User && applicant.User.UserConfigs && applicant.User.UserConfigs[0] && applicant.User.UserConfigs[0].city}, 
                                            {applicant.User && applicant.User.UserConfigs && applicant.User.UserConfigs[0] && applicant.User.UserConfigs[0].state}
                                    </p> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} onClick={(e) => this.showHotLeadPopOver(e, applicant.ApplicationScores || [])} >
                            {!!hotP && <div className="d-inline-block"><strong style={{fontSize: 14}}>{hotP > 66 ? "HOT" : hotP > 33 ? "MILD" : "LOW"}</strong></div>}
                            {["f8ad00", "f8ad00", "e40708"].map((v, i) => {
                                let barP = i === 0 ? 33 : (i === 1 ? 66 : 100);
                                let colorP = hotP / barP * 100;
                                let color = `rgb(255, 0, 0, ${colorP/100})`;
                                return <div style={{width: 10, height: 20, backgroundColor: color, borderRadius: 3}} className="mx-1 d-inline-block align-middle"></div>
                            })}
                        </Col>
                        <Col md={2} xs={12} className="text-center my-1">
                            <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(applicant)}>{applicant.connected_call_counts || ""}</span> 
                            {!CONSTANTS.OUTBOUND_BLOCKED_USERS.includes(user.user_id) && <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!applicant.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(applicant, selectedState), isConfirmMessage : "Are you sure to connect with "+applicant.User.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>}
                            {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!applicant.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: applicant.User})}> <img src={WhatsappIcon} alt="" style={{cursor : "pointer"}} /></Button></Badge> */}
                            <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: {user_id: applicant.user_id} })}>notifications_none</Icon></Button>
                            {/* <Button outline className="px-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.showApplicationComment(applicant)}>comment</Icon></Button> */}
                           
                            {["INACTIVE", "PENDING_ACTION"].indexOf(selectedState) !== -1 && 
                                <div>
                                    <Chip size="small" style={{fontSize: 12}} label={
                                        applicant.offer_status === 1 && applicant.status ===  CONSTANTS.APPLICATION_STATUS.SHORTLISTED ? "Selected": (
                                        applicant.offer_status === 0 && applicant.status ===  CONSTANTS.APPLICATION_STATUS.SHORTLISTED ? "Offer Accepted":(
                                        applicant.status !==  CONSTANTS.APPLICATION_STATUS.SHORTLISTED ? CONSTANTS.APPLICATION_STATUS_TEXT[applicant.status] :"")
                                        )
                                    } />
                                </div>
                            }
                        </Col>
                        <Col md={2} xs={12} className="text-center">
                            {
                                selectedState === "PENDING_ACTION" && !!applicant.isPendingWhatsapp &&
                                <span className="d-block" style={{fontSize: 10, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Whatsapp - Started {moment(applicant.isPendingWhatsapp.created_on).fromNow()}, Last: {moment(applicant.isPendingWhatsapp.modified_on).fromNow()} </span>
                            }
                            {
                                selectedState === "PENDING_ACTION" && !!applicant.isMissedCall &&
                                <span className="d-block" style={{fontSize: 10, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(applicant.isMissedCall.created_on).fromNow()} </span>
                            }
                            {/* {selectedState === "APPLIED" && 
                            <div>
                                <p className="mb-0 d-inline-block" style={{fontSize: 11}}>Slot Date : {moment(applicant.InterviewSlot.booking_date).format("ddd, Do MMM'YY")} <br />
                                Slot Time : {applicant.InterviewSlot.InterviewSlot && applicant.InterviewSlot.InterviewSlot.start_time} </p>
                                {moment(applicant.InterviewSlot.booking_date).diff(moment(), "days") <= 0 ?  <Icon fontSize="small" className="ml-3" style={{cursor: "pointer"}} onClick={(e) => this.setState({isConfirm : () => this.resetInterviewSlot(applicant.user_id)})}>update</Icon> : ""}
                            </div>
                            } */}
                            {/* {
                                selectedState === "SHORTLISTED" && (
                                    applicant.offer_status === 0 ?
                                     <Chip icon={<Icon style={{fontSize: 12, color: "orange"}} className="ml-2">warning</Icon>} style={{fontSize: 10}} label="Terms Not Accepted" size="small" variant="outlined" />
                                     :
                                     <Chip icon={<Icon style={{fontSize: 12}} className="ml-2">warning</Icon>} style={{fontSize: 10}} label="Terms Accepted" size="small" variant="outlined" />
                                )
                            } */}
                            <div>
                                {
                                    !!applicant.last_call && <span className="small" style={{fontSize: 10}}><span style={{fontSize: 10}}>&#128222;</span>{applicant.last_call.status}-{moment(applicant.last_call.call_scheduled_on || applicant.last_call.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")} {!!applicant.last_call.comment && `(${applicant.last_call.comment})`}</span>
                                }
                            </div>
                            {selectedState.includes("IN_TASK") && 
                                <>
                                    {/* <p className="mb-0" style={{fontSize: "11px"}}>Current Target : {applicant.currentTaskTarget}</p> */}
                                    {!!applicant.taskCounts && <p className="mb-0" style={{fontSize: "11px"}}>Task Counts : {applicant.taskCounts}</p>}
                                    {!!applicant.taskCounts && <p className="mb-0" style={{fontSize: "11px"}}>Last Active : {moment(applicant.last_task_date).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</p>}
                                </>
                            }
                            {
                                selectedState === "LEADS" && this.state.isShowSystemLead && <div style={{fontSize: 11}}>
                                    {
                                        applicant.Applications && applicant.Applications.map(app => 
                                            <strong className="d-block">{app.employer_name + "-" + app.job_title}</strong>
                                        )
                                    }
                                </div>
                            }
                         
                        </Col>
                        <Col md={1} xs={12} className="text-center d-flex">
                        {
                            CONSTANTS.APPLICANTS_STATES[selectedState] && CONSTANTS.APPLICANTS_STATES[selectedState].nextStates && Object.keys(CONSTANTS.APPLICANTS_STATES[selectedState].nextStates).map(nextState => 
                                <Button key={nextState} className="border-danger text-danger p-1 mr-1" style={{fontSize: 8, background: "none"}} onClick={() => this.toggleOpenModal(applicant.User, selectedState, nextState)}>{CONSTANTS.APPLICANTS_STATES[selectedState].nextStates[nextState]}</Button>
                            )
                        }
                        {
                            (selectedState.includes("IN_TASK") || selectedState.includes("IN_TRAINING")) && !!this.state.jobExtraDetails.length && 
                            <>
                                {applicant.extra_details_status === 0 && <Button className="border-danger text-danger p-1 mr-1" style={{fontSize: 8, background: "none", whiteSpace: "nowrap"}} onClick={() => this.UpdateExtraDetailStatus(applicant)}>ID Initiate</Button>}
                                {applicant.extra_details_status === 1 && <span className="ml-1" style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Initiated</span>}
                                {applicant.extra_details_status === 2 && <span className="ml-1" style={{fontSize: 10, color: "orange", whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px", color: "orange"}}>check_circle_outline</Icon>ID Requested</span>}
                                {applicant.extra_details_status === 3 && <span className="ml-1" style={{fontSize: 10, color: "green", cursor: "pointer", whiteSpace: "nowrap"}} onClick={() => this.showExtraInputs(applicant)}><Icon style={{fontSize: 10, lineHeight: "14px"}}>task_alt</Icon>ID Added</span>}
                                {applicant.extra_details_status === 4 && <span className="ml-1" style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Expired</span>}
                            </>
                        }
                        
                        </Col>
                        {selectedState === "LEADS" && this.state.isShowSystemLead ? 
                        <Col md={2} xs={12} className="text-right">
                            <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-secondary d-block">User Created</em>
                            <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{moment(applicant.User.created_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                        </Col>
                        :
                        <>
                        <Col  md={2} xs={12} className="text-center">
                        <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 d-block px-2 text-muted ml-4">{moment(applicant.modified_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                        <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{jobLocationListById[applicant.job_location_id] ? jobLocationListById[applicant.job_location_id].city : "-"}</em>  
                        </Col>
                        <Col md={1} xs={12} className="text-right"> 
                            <UncontrolledDropdown >
                                <DropdownToggle tag="Icon" className="text-right mr-3" style={{fontSize: 11}} >
                                <Icon style={{textDecoration : "underline" , cursor: "pointer"}} className="mr-3">more_vert</Icon>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem style={{fontSize: 12}} onClick={() => this.showUserExtraDetails(applicant)}>Show More Details</DropdownItem>
                                    {/* <DropdownItem style={{fontSize: 12}} onClick={() => this.setState({isOpenNotificationModal: applicant.user_id})}>Send Notification</DropdownItem> */}
                                    <DropdownItem style={{fontSize: 12}} onClick={() => this.setState({changeApplicationLocation: {application_id: applicant.application_id, status: selectedState, curr_job_location_id: applicant.job_location_id}})}>Change Location</DropdownItem>
                                    <DropdownItem style={{fontSize: 12}} disabled={!applicant.User.email} onClick={()=> {this.setState({isOpenWarningDetails: {user_id: applicant.User.user_id} })}}> Warning Letter</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        </>
                        
                        }
                        {this.state.isShowExtraDetails === applicant.user_id && 
                        <Col xs={12}>
                            <Row noGutters className="border-top">
                                <Col xs={6} className="border-right">
                                    <Row noGutters className="border-top px-3">
                                        <Col className="my-2" style={{fontSize: 12}}><b className="d-block">Rating </b>: {this.state.userRating}</Col>
                                            {this.state.userTaskCount.length ? 
                                            this.state.userTaskCount.map(task => 
                                            !(task.taskDetail.job_id && jobListById[task.taskDetail.job_id]) ? null :
                                            <Col xs={12} style={{fontSize: 12}} className="mt-2">
                                                <h6 className="border-bottom" style={{fontSize: 12}}>{jobListById[task.taskDetail.job_id].Employer.employer_name+"-"+jobListById[task.taskDetail.job_id].title}  : {task.taskDetail.task_title}</h6>
                                                <Row className="text-center">
                                                    <Col><span>Pending<br />{task.status_count[0] || 0}</span></Col>
                                                    <Col><span className="d-block">Verified<br />{task.status_count[1] || 0}</span></Col>
                                                    <Col><span>Rejected<br />{task.status_count[2] || 0}</span></Col>
                                                </Row>                                        
                                        </Col>
                                        ) : <em className="text-center mt-3 text-secondary" style={{fontSize: 12}}>No Task Activity Found</em>}
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row noGutters className="border-top px-3">
                                        <Col className="my-2" style={{fontSize: 12}}><b>Application Transition</b></Col>
                                    </Row>
                                    <Row noGutters className="text-center mb-2 border-bottom pb-1" style={{fontSize: 12, fontWeight: "bold"}}>
                                        <Col><span>State<br /></span></Col>
                                        <Col><span className="d-block">Date<br /></span></Col>
                                        <Col><span>Transitioned By<br /></span></Col>
                                    </Row>
                                    {
                                        this.state.transitionData.map(transition => 
                                            <Row noGutters className="text-center" style={{fontSize: 12}}>
                                                <Col><b>{CONSTANTS[transition.entity_type === "EXTRA_DETAILS_STATUS" ? "EXTRA_DETAILS_STATUS" : "APPLICATION_STATUS_TEXT"][transition.entity_id]}</b></Col>
                                                <Col><span className="d-block">{moment(transition.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mmA")}</span></Col>
                                                <Col><span>{transition["User.first_name"]}</span></Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                            
                        </Col>
                        }
                    </Row>
                    })
                    : 
                    <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>


            }

            <TablePagination labelRowsPerPage="" rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={applicantList.length} rowsPerPage={this.state.applicantRowsPerPage} page={this.state.applicantCurrentPage}
                onChangePage={ (e, page) => this.setState({applicantCurrentPage : page}) }
                onChangeRowsPerPage={ (e) =>  this.setState({applicantRowsPerPage : e.target.value})}
            />

            <Popover
                id="mouse-over-popover"
                sx={{pointerEvents: 'none'}}
                open={!!isOpenHotLeadPopOver}
                anchorEl={isOpenHotLeadPopOver && isOpenHotLeadPopOver.el}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                onClose={() => this.setState({isOpenHotLeadPopOver: null})}
                disableRestoreFocus
            >
                <div className="p-2 small">
                    <strong className="mb-2 d-block">Lead Recommendation Criteria:</strong>
                {
                    isOpenHotLeadPopOver && isOpenHotLeadPopOver.data.map(obj => {
                        return <div><em>{obj.score_type}: </em> <span className="float-right">{parseInt(obj.score_value)}</span></div>
                    })
                }
                </div>
            </Popover>


            {!!this.state.isOpenCardModel && <PartnerDetail {...this.state.isOpenCardModel} toggle={() => this.setState({isOpenCardModel : false})} />}

            <Modal size="md" isOpen={isOpenWarningDetails} toggle={()=> {this.setState({isOpenWarningDetails : false})}} >
                <ModalHeader toggle={()=> {this.setState({isOpenWarningDetails : false})}}> Warning Letter </ModalHeader>
                <ModalBody className="px-4">
                    <RichTextEditor placeholder="Enter Warning Reason" value={warningContent || ""} onChange={(value)=> this.setState({ warningContent : value }) }/>
                    <Row className={"border-top mt-2"}> 
                        <Col className="text-right mt-2">
                            <Button size="sm" type="submit" color="dark" onClick={() => this.sendWarningLetterDetails()}>Send Warning</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={!!this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
                <form onSubmit={this.handleNotificationSubmit}>
                <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                <ModalBody>
                    {/* <label className="radio-inline"><input type="radio" className="mr-3" name="notification_type" value="NOTIFICATION" checked />General Notification</label>
                    <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label> */}
                    <InputFeild required placeholder="Title" name="title" />
                    <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                    {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                    
                    <label className="mt-2">Select Notification Redirection Screen: </label><br />
                    {CONSTANTS.NOTIFICATION_ORIGIN.map(origin => 
                        <Chip size="small" label={origin.title} onClick={(e) => {isOpenNotificationModal.originType = origin.id; this.setState({isOpenNotificationModal})}} style={{background: isOpenNotificationModal.originType === origin.id ? "#999": "#ddd"}} clickable className="mr-2"/>
                    )}
                    <Row className="mt-2">
                        {["STAGES", "JOB_TASK", "TASK_LEADERBOARD"].indexOf(isOpenNotificationModal.originType) !== -1 && <Col>
                            <SelectList search={true} placeholder="Select Project" value={isOpenNotificationModal.job_id || ""} onChange ={(e) => { isOpenNotificationModal.job_id = e.target.value; isOpenNotificationModal.job_task_id = null; this.setState({ isOpenNotificationModal }); this.fetchJobTaskForNotification(e.target.value) } } >
                            { 
                                jobList.map(job => 
                                <option key={job.job_id} value={job.job_id}> 
                                    {job.Employer.employer_name +  "  -  "  + job.title} 
                                    {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                </option>
                                )
                            }
                            </SelectList>
                        </Col>}
                        {["JOB_TASK", "TASK_LEADERBOARD"].indexOf(isOpenNotificationModal.originType) !== -1 && <Col>
                            <SelectList search={true} placeholder="Select Task" value={isOpenNotificationModal.job_task_id || ""} onChange ={(e) => { isOpenNotificationModal.job_task_id = e.target.value; this.setState({ isOpenNotificationModal }); } }  >
                                {isOpenNotificationModal && isOpenNotificationModal.taskData && isOpenNotificationModal.taskData.map(task => 
                                    <option key={task.job_task_id} value={task.job_task_id}>{task.task_title}</option>
                                )}
                            </SelectList>
                        </Col>}
                    </Row>

                </ModalBody>
                <ModalFooter style={{height: "45px"}}>
                <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
            </ModalFooter>
            </form>
            </Modal>

            <Modal centered={true} isOpen={!!changeApplicationLocation} toggle={()=> {this.setState({changeApplicationLocation : false})}}>
                <ModalHeader toggle={()=> {this.setState({changeApplicationLocation : false})}}>Change partner location</ModalHeader>
                <ModalBody>
                    <SelectList placeholder="Select Location" value={changeApplicationLocation.job_location_id} onChange ={(e) => {changeApplicationLocation.job_location_id = e.target.value; this.setState({changeApplicationLocation})}} >
                    { 
                        (selectedJob ? selectedJob.JobLocations : []).filter(loc => loc.job_location_id !== changeApplicationLocation.curr_job_location_id).map(loc => 
                        <option key={loc.job_location_id} value={loc.job_location_id}> {loc.city} </option>
                        )
                    }
                    </SelectList>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.changeApplicationLocation} className="buttonred">Update</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={!!isOpenComment} toggle={() => {this.setState({isOpenComment : false})}}>
                <ModalHeader toggle={() => {this.setState({isOpenComment : false})}}> <h5>Comments</h5></ModalHeader>
                <ModalBody>
                    <Button className="buttonred mb-3" onClick={() => { isOpenComment.isAdd = true; this.setState({isOpenComment: this.state.isOpenComment})}}>Add Comment</Button>
                    {isOpenComment && isOpenComment.isAdd && <form onSubmit={this.addApplicationComment}>
                        <textarea required placeholder="Enter Comment" name="comment" className="w-100" rows={4} />
                        <div className="text-right"><Button className="buttonred">Submit</Button></div>
                    </form>}
                    {isOpenComment && isOpenComment.data.length ? 
                    <ul className="pl-3 mt-3"> 
                        {isOpenComment && isOpenComment.data.map(comment => <li>{comment.comment}<span className="float-right" style={{fontSize:10, color: "#666"}}>{moment(comment.created_on).utcOffset("+05:30").format("Do MMM YY hh:mmA")}</span></li>)}
                    </ul>
                    :
                    <div className="text-center mt-3"><em>No Comments</em></div>
                    }
                </ModalBody>
            </Modal>
            <Modal  size="lg" isOpen={this.state.isOpenUpdateModal} toggle={() => this.setState({isOpenUpdateModal : false})}>
                <ModalHeader ><h4>Change Status</h4></ModalHeader>
                <ModalBody>
                {!!this.state.isOpenUpdateModal && <UpdateApplicationState modalInfo={this.state.isOpenUpdateModal} getApplicantsByState={this.getApplicants} toggleOpenModal={() => this.setState({isOpenUpdateModal : false})}/>  } 
                </ModalBody>
            </Modal>
            <Modal isOpen={addLead}>
                <ModalHeader toggle={() => {this.setState({addLead : false})}}>
                    <Row className="px-2">
                        <Col xs="auto" onClick={() => this.setState({addLead: 1 })} className={" " + (addLead === 1 && "border-bottom")} style={{cursor: "pointer"}}><h6>Add Lead</h6></Col>
                        <Col xs="auto" className="border-right px-2"></Col>
                        <Col xs="auto" onClick={() => this.setState({addLead: 2})} className={"ml-2 " + (addLead === 2 && "border-bottom")} style={{cursor: "pointer"}}><h6>Upload Lead</h6></Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                {addLead === 1 && <AddLeads jobData={selectedJob} getApplicantsByState={this.getApplicants} toggleOpenModal={() => this.setState({addLead: false})}/>  } 
                {addLead === 2 && <UploadLeads close={() => this.setState({addLead: false})} jobData={selectedJob} getApplicantsByState={this.getApplicants} toggleOpenModal={() => this.setState({addLead: false})}/>  } 
                </ModalBody>
            </Modal>

            <Modal isOpen={!!isUploadExtraDetails} toggle={()=> {this.setState({isUploadExtraDetails : false})}} >
                <form onSubmit={this.uploadExtraDetails}>
                    <ModalHeader toggle={()=> {this.setState({isUploadExtraDetails : false})}}>Add IDs Data by Excel</ModalHeader>
                    <ModalBody className="p-5">
                        <input required type="file" name="csvFile" />
                        <p>*You have to upload the file that you have downloaded and added the data</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button name="uploadRequested" type="submit" className="buttonred mr-2" >Upload Requested IDs</Button>{' '}
                        <Button name="uploadAdded" type="submit" className="buttonred" >Upload IDs Data</Button>{' '}
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={!!this.state.isDownloadExtraDetails} toggle={()=> this.openDownloadExtraDetails(false)} >
                    <ModalHeader toggle={()=> this.openDownloadExtraDetails(false)}>Download IDs Data</ModalHeader>
                    <ModalBody className="p-5">
                        <Row >
                            <Col xs={5}>
                                <DatePicker
                                    placeholderText="Start Date"
                                    selected={isDownloadExtraDetails.start_date || null}
                                    isClearable
                                    dateFormat="dd MMM yyyy"
                                    onChange={(date) => {isDownloadExtraDetails.start_date = date; this.setState({isDownloadExtraDetails})}}
                                />
                            </Col>
                            <Col xs={5}>
                                <DatePicker
                                    placeholderText="End Date"
                                    selected={isDownloadExtraDetails.end_date || null}
                                    isClearable
                                    dateFormat="dd MMM yyyy"
                                    onChange={(date) => {isDownloadExtraDetails.end_date = date; this.setState({isDownloadExtraDetails})}}                          
                                />
                            </Col>
                            <Col xs={2}><Button className="buttonred" onClick={() => this.openDownloadExtraDetails(true)} style={{fontSize: 10}}>Filter</Button></Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6} className="border shadow-sm text-center p-2">
                                <span style={{fontWeight: "bold"}} className="">{this.state.isDownloadExtraDetails.countsData ? (this.state.isDownloadExtraDetails.countsData.REQUEST_INITIATED+this.state.isDownloadExtraDetails.countsData.EXPIRED) : 0}</span>
                                <span className="small">({this.state.isDownloadExtraDetails.countsData ? this.state.isDownloadExtraDetails.countsData.EXPIRED : 0} Expired)</span>
                                <span style={{fontSize: 14 }} className="text-secondary d-block my-2">New Requests</span>
                                <Button className="buttonred" style={{fontSize: 10}} onClick={() => this.downloadExtraDetails("REQUEST_INITIATED_ALL")}>Download</Button>
                                {/* <Button className="buttonred" style={{fontSize: 10}} onClick={() => this.downloadExtraDetails("REQUEST_INITIATED")}>Download</Button> */}
                            </Col>
                            <Col xs={6} className="border shadow-sm text-center p-2">
                                <span style={{fontWeight: "bold"}} className="d-block">{this.state.isDownloadExtraDetails.countsData ? this.state.isDownloadExtraDetails.countsData.REQUEST_REACTIVATION : 0}</span>
                                <span style={{fontSize: 14 }} className="text-secondary d-block my-2">Reactivation Requests</span>
                                <Button className="buttonred" style={{fontSize: 10}} onClick={() => this.downloadExtraDetails("REQUEST_REACTIVATION")}>Download</Button>
                            </Col>
                            <Col xs={6} className="border shadow-sm text-center p-2">
                                <span style={{fontWeight: "bold"}} className="d-block">{this.state.isDownloadExtraDetails.countsData ? this.state.isDownloadExtraDetails.countsData.REQUESTED : 0}</span>
                                <span style={{fontSize: 14, color: "orange"}} className="d-block my-2">Requests In-Process</span>
                                <Button className="buttonred" style={{fontSize: 10}} onClick={() => this.downloadExtraDetails("REQUESTED")}>Download</Button>
                            </Col>
                            <Col xs={6} className="border shadow-sm text-center p-2">
                                <span style={{fontWeight: "bold"}} className="d-block">{this.state.isDownloadExtraDetails.countsData ? this.state.isDownloadExtraDetails.countsData.ADDED : 0}</span>
                                <span style={{fontSize: 14, color: "green"}} className="d-block my-2">Uploaded IDs</span>
                                <Button className="buttonred" style={{fontSize: 10}} onClick={() => this.downloadExtraDetails("ADDED")}>Download</Button>
                            </Col>
                           
                        </Row>
                    </ModalBody>
            </Modal>
            

            <Modal isOpen={!!isShowExtraInputs} toggle={()=> {this.setState({isShowExtraInputs : false})}} >
                    <ModalHeader toggle={()=> {this.setState({isShowExtraInputs : false})}}>Partner ID Details</ModalHeader>
                    <ModalBody className="p-5">
                        {
                            this.state.jobExtraDetails.map(input => {
                                var data = (isShowExtraInputs || []).filter(o => o.job_extra_detail_id === input.job_extra_detail_id)[0];
                                return <div style={{fontSize: 14}}>
                                    <strong>{input.title}: </strong>
                                    <span>{data && data.input_value}</span>
                                </div>
                            })
                        }
                    </ModalBody>
            </Modal>


            <Modal size={"lg"} centered isOpen={!!this.state.callData.length} toggle={()=> {this.setState({callData : []})}} >
                    <ModalHeader toggle={()=> {this.setState({callData : []})}}>Call Details</ModalHeader>
                    <ModalBody className="p-2">
                    <Table bordered className="table-sm mt-2 mb-0 text-center" style={{fontSize: 14}}>
                                <thead>
                                    <tr>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.callData.map((call, i) =>
                                    <tr>
                                      <td>{(call.userDetail || {}).first_name}</td>
                                      <td>{call.direction === 1 ? "In" : "Out"}</td>
                                      <td>
                                        {call.call_recording_url ?
                                        <audio preload={true} style={{maxWidth: 185}} controls><source src={accessExotelCallRecording(call.call_recording_url)} type="audio/ogg"  /></audio>
                                        :
                                        <span>{call.status}</span>
                                        }
                                      </td> 
                                      <td><span>{call.comment}</span></td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em>
                                      </td>
                                    </tr>
                                  ) 
                                  }
                                </tbody>
                            </Table>  
                    </ModalBody>
            </Modal>



            {!!this.state.whatsappChat && <WhatsappChat jobList={jobList} user={this.state.whatsappChat} entity_type={"JOB"} entity_id={this.state.selectedJobId} jobTitle={selectedJob.Employer.employer_name + "-" + selectedJob.title} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => this.markWAMessageRead(this.state.whatsappChat)} />}

            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
            {!!this.state.callDetails && <OutboundCallingStatus template_id={this.state.callDetails.template_id} failure_message={this.state.callDetails.failure_message} calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}
        </div>
        );
    }
}

export default withRouter(RecruitmentComponent);
