import {  Badge, Chip, FormGroup, Icon, Input, List, TablePagination } from "@material-ui/core";
import React, { Component } from "react";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import SelectList from "../../global/form/select";
import InputFeild from "../../global/form/input";
import { Button, CardBody, Modal, ModalBody, ModalHeader, Collapse, Card, ModalFooter } from "reactstrap";
import moment from "moment";
import CONSTANTS from "../../../constants";
import store from "../../../store";
import { FILTERS } from "../../../constants/campaign_filter";
import { Height } from "@material-ui/icons";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg";
import SendNotifications from "../giggers/send_notification";
import ReactDatePicker from "react-datepicker";



class CampaignLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
       
            selectedJob :  this.props.selectedJob ,       
            isOpenPushNotificationModal: false,
            isOpenWhatsappModal: false,
            isOpenEmailModal: false,
            campaignList: [],
            scheduleNotificationDetails: {},
            selectedList: {},
            currentUserPage: 0,
            rowsPerPage: 20,
            selectedRedirection: null
          };

  }

    componentDidMount(){
    this.fetchCampaignList();
    }

    fetchJobTaskForNotification = async (job_id) => {
        store.dispatch(setLoader(true));
        let { isOpenPushNotificationModal } = this.state;
        try{
            let apiData = await ApiEndpoints.JobTask.fetch({job_id});
            isOpenPushNotificationModal.taskData = apiData.data.data;
            await this.setState({ isOpenPushNotificationModal });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
        fetchCampaignList = async(page = 0) => {
            store.dispatch(setLoader(true))
            try{
                var body = { 
                    limit: this.state.rowsPerPage ,
                    offset : page * this.state.rowsPerPage
                };
                let apiData = await ApiEndpoints.CampaignFilter.fetchCampaigns(body);
                let campaignList = apiData.data.data;
                this.setState({ campaignList, currentUserPage: page })
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));

        }

        onChangeScheduleNotification = (e) => {
                let { scheduleNotificationDetails } = this.state; 
                scheduleNotificationDetails[e.target.name] = e.target.value;
                this.setState({scheduleNotificationDetails, currentUserPage: 0})
        }

        onCloseNotification = async (e) => {
            this.setState({isOpenPushNotificationModal: false, isOpenWhatsappModal: false, isOpenEmailModal: false, scheduleNotificationDetails: {}, scheduled_on: false});
            this.fetchCampaignList();
        }

        scheduleNotification = async (e) => {
            e.preventDefault();
                let {scheduleNotificationDetails, selectedRedirection, selectedList, isOpenPushNotificationModal, isOpenEmailModal, isOpenWhatsappModal,} = this.state;
               
                store.dispatch(setLoader(true))
                try{
                    let body = {
                        campaign_id: selectedList.campaign_id,
                        type : isOpenPushNotificationModal ? 0 : isOpenWhatsappModal ? 2 : 1,
                        notification_data : scheduleNotificationDetails
                    }
                if (selectedRedirection) body.notification_data.redirection_screen = selectedRedirection
                let resp = await ApiEndpoints.CampaignFilter.scheduleCampagin(body)
                await this.setState({scheduleNotificationDetails : resp.data.data});
                this.setState({isOpenPushNotificationModal: false, isOpenWhatsappModal: false, isOpenEmailModal: false, scheduleNotificationDetails: {}});
                this.fetchCampaignList();

                // store.dispatch(setSuccess({show: true, message: resp.data.message}))
                }
                catch(e){
                    store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
                }
                store.dispatch(setLoader(false));

    }


    readHTMLFile = (e) => {
        e.preventDefault()
        let { scheduleNotificationDetails } = this.state;
        const reader = new FileReader()
        reader.onload = async (e) => { 
            scheduleNotificationDetails.html = (e.target.result)
            this.setState({ scheduleNotificationDetails })
        //   alert(text)
        };
        reader.readAsText(e.target.files[0])
    }

    selectRedirectionType = async (type) => {
        await this.setState({ selectedRedirection: type});
    }

    sendTestEmail = async (opts) => {
        store.dispatch(setLoader(true));
        let user = store.getState().user;
        try{
        await ApiEndpoints.CampaignFilter.sendTestEmail(opts)
        store.dispatch(setSuccess({show: true, message: "Sent Test Email to your email: "+user.email}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }
    sendTestWhatsapp = async (opts) => {
        store.dispatch(setLoader(true));
        let user = store.getState().user;
        try{
        await ApiEndpoints.CampaignFilter.sendTestWhatsapp(opts)
        store.dispatch(setSuccess({show: true, message: "Sent Test Whatsapp to your Number: "+user.mobile}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }


  render() {
    let {campaignList, selectedList, isOpenPushNotificationModal, selectedRedirection, isOpenWhatsappModal, isOpenEmailModal, scheduleNotificationDetails} = this.state;
    let jobList = store.getState().jobList

    return (
      <>
            
            <Row className="py-2 m-0 small my-3 font-weight-bold bg-secondary text-light align-items-center">
                <Col xs={2}><span>Title</span></Col>
                <Col xs={5}><span>Description</span></Col>
                <Col xs={1}><span>Total Users</span></Col>
                <Col xs={2}></Col>
                <Col xs={2}><span>Action</span></Col>
            </Row>

                {campaignList && campaignList.length ? 
                   <div>
                        {campaignList
                        .slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage)
                        .map(List => 
                                <Row className="border-bottom m-0 py-2 my-1 align-items-center" style={{fontSize: 13}} >
                                    <Col xs={2}><span>{List.title}</span></Col>
                                    <Col xs={5}><span>{List.description}</span></Col>
                                    <Col xs={1}><span className="text-danger d-block ml-1">{List.total_users} </span></Col>
                                    <Col xs={2}>
                                    <span> 
                                        (
                                        {List.ScheduledCampaigns.filter(notification => notification.type === 0).length ? List.ScheduledCampaigns.filter(notification => notification.type === 0)[0].total_users || "..." : "NA"} / 
                                        {List.ScheduledCampaigns.filter(notification => notification.type === 2).length ? List.ScheduledCampaigns.filter(notification => notification.type === 2)[0].total_users || "..." : "NA"} / 
                                        {List.ScheduledCampaigns.filter(notification => notification.type === 1).length ? List.ScheduledCampaigns.filter(notification => notification.type === 1)[0].total_users || "..." : "NA"}
                                        )</span>
                                    </Col>

                                    <Col xs={2}>
                                      
                                    {
                                        List.ScheduledCampaigns.filter(camp => camp.type === 0).length
                                        ? <Button disabled outline className="px-1 mr-1 pb-1 pt-0" color="secondary" ><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} >notifications_none</Icon></Button>
                                        : <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary" onClick={()=> {this.setState({isOpenPushNotificationModal : true, selectedList: List})}}><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} >notifications_none</Icon></Button>
                                     }

                                     {
                                        List.ScheduledCampaigns.filter(camp => camp.type === 2).length
                                       ? <Button disabled outline className="px-1 mr-1 pb-1 pt-0" color="success"> <img src={WhatsappIcon} alt="" style={{cursor : "pointer"}} /></Button>
                                       : <Button outline className="px-1 mr-1 pb-1 pt-0" color="success" onClick={()=> {this.setState({isOpenWhatsappModal : true, selectedList: List})}}> <img src={WhatsappIcon} alt="" style={{cursor : "pointer"}} /></Button>
                                     }

                                     {
                                        List.ScheduledCampaigns.filter(camp => camp.type === 1).length
                                        ? <Button disabled outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}}>mail_outline</Icon></Button>
                                        : <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary" onClick={()=> {this.setState({isOpenEmailModal : true, selectedList: List})}}><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}}>mail_outline</Icon></Button>

                                     }
                                       
                                    </Col>
                                </Row>
                                )
                            } 
                            <TablePagination rowsPerPageOptions={[5, 20, 40, 60, 100, 200]} component="div" count={campaignList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                            onChangePage={ (e, page) => this.setState({currentUserPage: page}) }
                            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                            />
                    </div>
                    
                    : 
                    <em className="d-block m-3 text-center text-muted">No Data</em>
                }
            
            <Modal centered isOpen={isOpenPushNotificationModal} toggle={() => this.setState({isOpenPushNotificationModal: false})}>
                <ModalHeader toggle={() => this.setState({isOpenPushNotificationModal: false})}>
                    <h5 className="text-center text-dark mt-1" style={{fontSize: 16}}>Push Notification</h5>
                </ModalHeader>
                {/* <form onSubmit={this.scheduleNotification}> */}
                    <ModalBody>
                            {/* <InputFeild required={true} name="title" className="mb-1" placeholder="Enter your title" style={{fontSize: 14}} value={scheduleNotificationDetails.title} onChange={this.onChangeScheduleNotification}/>
                            <textarea required={true} placeholder="Enter your message" name="description" rows={6} className=" w-100 mt-2 mb-3" style={{fontSize: 14}} value={scheduleNotificationDetails.description} onChange={this.onChangeScheduleNotification}/>
                            */}

                        <SendNotifications onClose={this.onCloseNotification} campaignNotification={true} campaign_id={selectedList.campaign_id} />
                    </ModalBody>
                {/* </form> */}
            </Modal>

            <Modal centered isOpen={isOpenWhatsappModal} toggle={() => this.setState({isOpenWhatsappModal: false, scheduled_on: false})}>
                <ModalHeader toggle={() => this.setState({isOpenWhatsappModal: false, scheduled_on: false})}>
                    <h5 className="text-center text-dark mt-1" style={{fontSize: 16}}>Whatsapp Notification</h5>
                </ModalHeader>
                    <form onSubmit={this.scheduleNotification}>
                        <ModalBody>
                            <textarea required={true} placeholder="Enter template name" name="template" rows={6} className=" w-100 mt-2 mb-3" style={{fontSize: 14}} value={scheduleNotificationDetails.template} onChange={this.onChangeScheduleNotification}/>
                            <Row>
                                <Col xs={8}>
                                    <ReactDatePicker
                                        name="scheduled_on"
                                        selected={this.state.scheduled_on ? moment(this.state.scheduled_on).toDate() : undefined}
                                        onChange={(e) => this.setState({ scheduled_on: e})}
                                        shouldCloseOnSelect={true}
                                        minDate={moment().toDate()}
                                        maxDate={moment().add(30, "days").toDate()}
                                        isClearable={true}
                                        placeholderText="Schedule on"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={12} className="text-right mt-3">
                                <Button type="button" className="buttonred mr-2" onClick={() => this.sendTestWhatsapp(scheduleNotificationDetails)}>Send Test</Button>
                                <Button type="submit" className="buttonred mr-2">{this.state.scheduled_on ? "Schedule" : "Publish Message"}</Button>
                            </Col>
                            </Row>                      
                        </ModalBody>
                    </form>
            </Modal>

            <Modal size="lg" centered isOpen={isOpenEmailModal} toggle={() => this.setState({isOpenEmailModal: false, scheduled_on: false})}>
                <ModalHeader toggle={() => this.setState({isOpenEmailModal: false, scheduled_on: false})}>
                    <h5 className="text-center text-dark mt-1" style={{fontSize: 16}}>Email</h5>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={6}>
                            <form onSubmit={this.scheduleNotification}>
                                <InputFeild required={true} name="subject" className="mb-1" placeholder="Enter Subject" style={{fontSize: 14}} value={scheduleNotificationDetails.subject} onChange={this.onChangeScheduleNotification}/>
                                <textarea required={true} placeholder="Paste HTML content here" name="html" rows={15} className=" w-100 mt-2 mb-2" style={{fontSize: 14}} value={scheduleNotificationDetails.html} onChange={this.onChangeScheduleNotification}/>
                                <span className="d-block">OR</span>
                                <input className="mt-2" type="file" title="Select HTML File" accept=".htm,.html, .txt" onChange={(e) => this.readHTMLFile(e)} />
                                <Row className="mt-3">
                                    <Col xs={10}>
                                        <ReactDatePicker
                                            name="scheduled_on"
                                            selected={this.state.scheduled_on ? moment(this.state.scheduled_on).toDate() : undefined}
                                            onChange={(e) => this.setState({ scheduled_on: e})}
                                            shouldCloseOnSelect={true}
                                            minDate={moment().toDate()}
                                            maxDate={moment().add(30, "days").toDate()}
                                            isClearable={true}
                                            placeholderText="Schedule on"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} className="text-right">
                                        <Button type="button" className="buttonred mr-2" onClick={() => this.sendTestEmail(scheduleNotificationDetails)}>Send Test</Button>
                                        <Button type="submit" className="buttonred mr-2">{this.state.scheduled_on ? "Schedule" : "Publish Email"}</Button>
                                    </Col>
                                </Row> 
                            </form>
                        </Col>
                        <Col xs={6}>
                            <iframe title="HTML Preview" style={{height: "100%", width: "100%"}} srcdoc={scheduleNotificationDetails.html} />
                            {/* <div dangerouslySetInnerHTML={{__html: scheduleNotificationDetails.html}} ></div> */}
                        </Col>
                    </Row>
                    
                </ModalBody>
            </Modal>
      </>
    );
  }
}

export default CampaignLists;
